<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "@/router/layouts/main";
import EventBus from "@/event-bus";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";
import Preloader from "@/components/preloader";
import ApplicationHeader from "@/components/applications/application-header";
import ApplicationPreview from "@/components/applications/application-preview";
import SidebarDetails from "@/components/applications/sidebar-details";
import SidebarAttachments from "@/components/applications/sidebar-attachments";
import Schedule from "@/components/applications/schedule";
import ScheduleApi from "@/components/applications/schedule-api";
import "vue2-datepicker/locale/pl";
import vue2Dropzone from "vue2-dropzone";
import PageHeader from "@/components/page-header";
import { roleService } from "@/app/service/roleService";
import { appService } from "@/app/service/appService";
const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentApplicationAttachmentRepository = Repository.get(
  "PaymentApplicationAttachmentRepository"
);

export default {
  components: {
    Preloader,
    PageHeader,
    Layout,
    vueDropzone: vue2Dropzone,
    ApplicationHeader,
    ApplicationPreview,
    SidebarDetails,
    SidebarAttachments,
    Schedule,
    ScheduleApi,
  },
  data() {
    return {
      accountsList: [],
      inputLoading: false,
      title: "Szczegóły wniosku",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Szczegóły wniosku",
          to: {
            name: "applications-details",
            params: { uuid: this.$route.params.uuid },
          },
          active: false,
        },
        {
          text: "Zmiana statusu",
          active: true,
        },
      ],
      detailsEditForm: {
        attachments: [],
      },
      disabledBtn: false,
      preloader: true,
      itemData: null,
      scheduleData: null,
      loggedUser: null,
      dropzoneOptions: {
        url: PaymentApplicationAttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
    };
  },
  validations() {
    return {
      detailsEditForm: {
        attachments: [],
      },
    };
  },
  created() {
    if (this.$route.params.uuid) {
      this.loadData(this.$route.params.uuid);
    }
    EventBus.$on("refreshAppDetailsPage", (id) => {
      this.loadData(id);
    });
  },
  beforeDestroy() {
    EventBus.$off("refreshAppDetailsPage");
  },
  computed: {
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
    isAdmin() {
      return roleService.isAdmin();
    },
  },
  methods: {
    async loadData(id) {
      if (id) {
        this.inputLoading = true;
        try {
          const promise = await PaymentApplicationRepository.get(id);
          const promise2 = await PaymentCompanyRepository.getBankAccounts(promise.data.companyId);
          if (
            promise.data.typeOfApplication === 'Przelew skarbowy' &&
            promise.data.applicationSpecificData && promise.data.applicationSpecificData.cycleId
          )
          {
            try {
              const schedule = await PaymentApplicationRepository.getSchedule(id);
              await Promise.all([schedule]);
              this.scheduleData = schedule.data;
            } catch (error) {
              console.log(error);
            }
          }
          await Promise.all([promise, promise2]);
          this.itemData = promise.data;
          this.accountsList = promise2.data;

          this.preloader = false;
          this.inputLoading = false;
        } catch (error) {
          console.log(error);
          this.preloader = false;
          this.inputLoading = false;
        }
      }
    },
    compareUserToAcceptor(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a);
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    dropzoneAfterSuccess(file, response) {
      this.detailsEditForm.attachments.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.detailsEditForm.attachments.length; i++) {
        if (!this.detailsEditForm.attachments.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.detailsEditForm.attachments[i] === file.tempUploadUuid
          ) {
            this.detailsEditForm.attachments.splice(i, 1);
          }
        }
      }
    },
    dropzoneSendingEvent(file, xhr, formData) {
      formData.append("type", 2);
    },
    accountLabel({ bankAccountName, bankAccountNumber, bankAccountId }) {
      return `${bankAccountName} ${bankAccountNumber} ${bankAccountId}`;
    },
    changeStatus(statusNumber) {
      Swal.fire({
        title: "Podaj przyczynę zmiany statusu wniosku",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Zmień status",
        cancelButtonText: "Anuluj",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#61aa3d",
        cancelButtonColor: "#e2001a",
        inputValidator: (reason) => {
          if (!reason) {
            return "Podaj przyczynę!";
          }
        },
        preConfirm: (reason) => {
          let payload = {
            applicationStatus: statusNumber,
            attachments: this.detailsEditForm.attachments,
            version: this.itemData.version,
            statusMessage: reason,
          };
          this.onComplete(payload);
        },
        allowOutsideClick: false,
      }).then((reason) => {
        if (reason.value) {
          "SUKCES!", "Status wniosku został zmieniony!", "success";
        } else if (reason.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            "Anulowano",
            "Status wniosku nie został zmieniony!",
            "error"
          );
          this.disabledBtn = false;
          this.preloader = false;
        }
      });
    },
    changeStatusToRealised(statusNumber) {
      let payload = {
        applicationStatus: statusNumber,
        attachments: this.detailsEditForm.attachments,
        version: this.itemData.version,
      };
      this.onComplete(payload);
    },
    onComplete(payload) {
      this.disabledBtn = true;
      this.preloader = true;

      PaymentApplicationRepository.updateItem(
        payload,
        this.itemData.applicationId
      )
        .then((response) => {
          this.disabledBtn = false;
          this.preloader = false;

          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Status wniosku został zmieniony",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "home",
              });
            },
            // onClose: () => {
            //   this.$router.push({
            //     name: "applications-details",
            //     params: { uuid: this.itemData.applicationId },
            //   });
            // },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            toast: false,
            position: "top",
            title: "Błąd! ",
            text: "Nie można zmienić statusu wniosku: " + error.response.data.message,
            showConfirmButton: false,
            timer: 3500,
          });
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="itemData">
      <div class="col-md-8">
        <div class="card shadow-sm">
          <div class="card-body">
            <ApplicationHeader :itemData="itemData" :collapse="'collapseApplicationDetails'" :backUrl="{ name: 'applications-details', params: { uuid: itemData.applicationId }}" />
            <b-collapse id="collapseApplicationDetails">
              <hr />
              <ApplicationPreview :itemData="itemData" />
            </b-collapse>
          </div>
        </div>

        <div class="card shadow-sm" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5])">
          <div class="card-header">Zmiana statusu wniosku</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-alert variant="info" class="mb-3" show fade v-if="itemData.confirmTransfer">
                  <i class="mdi mdi-information-outline mr-2"></i>Wnioskujący zlecił dołączenie potwierdzenia wykonania przelewu.
                </b-alert>
                <div class="form-group" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5])">
                  <label for="dropzone">Załączniki</label>
                  <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-sending="dropzoneSendingEvent" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true>
                    <div class="dropzone-custom-content dz-message">
                      upuść plik tutaj lub kliknij, aby przesłać potwierdzenie przelewu
                    </div>
                  </vue-dropzone>
                </div>
              </div>
              <div class="col-md-6">
                <b-button block :disabled="disabledBtn" variant="success" v-on:click="changeStatusToRealised(7)">Zrealizowany</b-button>
              </div>
              <div class="col-md-6 text-right">
                <b-button :disabled="disabledBtn" variant="warning" v-on:click="changeStatus(4)">Przedawniony</b-button>
                <b-button :disabled="disabledBtn" variant="danger" v-on:click="changeStatus(6)" class="ml-2">Odrzucony przez bank</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm" v-else>
          <div class="card-header">Zmiana statusu wniosku</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <b-alert variant="danger" class="mb-1" show fade>
                  <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie posiadasz uprawnień do modyfikacji tego zasobu lub wniosek jest w nieodpowiednim statusie.
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-0">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-playlist-star"></i>
                </span>
                <span class="d-none d-sm-inline-block">Dane wniosku</span>
              </template>
              <SidebarDetails :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.attachments && this.itemData.attachments.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-attachment"></i>
                </span>
                <el-badge :value="itemData.attachments.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Załączniki</span>
                </el-badge>
              </template>
              <SidebarAttachments :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.applicationSpecificData.isRecurring && this.itemData.applicationSpecificData.schedule.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="itemData.applicationSpecificData.schedule.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <Schedule :itemData="itemData" />
            </b-tab>
            <b-tab v-else-if="scheduleData">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="scheduleData.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <ScheduleApi :itemData="itemData" :scheduleData="scheduleData" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>